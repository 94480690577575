var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.colorSnackbar,
                  mensagemSnackbar: _vm.mensagemSnackbar
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "grey--text darken-2 font-weight-black justify-center title-2"
                    },
                    [
                      _c("v-icon", { staticClass: "primary--text ma-1 pa-1" }, [
                        _vm._v(" keyboard_arrow_down ")
                      ]),
                      _vm._v(" Informe um nome para sua campanha")
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "ma-3 pa-3",
                      attrs: { color: "grey lighten-3" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "align-self-center",
                              attrs: { lg: "11" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-5",
                                attrs: {
                                  solo: "",
                                  rules: _vm.requiredRules,
                                  required: "",
                                  readonly: !_vm.newCampaign,
                                  "prepend-inner-icon": "mdi-format-title",
                                  placeholder: "Nome da Campanha",
                                  hint:
                                    "O nome é apenas de uso interno da plataforma"
                                },
                                model: {
                                  value: _vm.campanha.campaign_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.campanha, "campaign_name", $$v)
                                  },
                                  expression: "campanha.campaign_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "grey--text darken-2 font-weight-black justify-center title-2"
                    },
                    [
                      _c("v-icon", { staticClass: "primary--text ma-1 pa-1" }, [
                        _vm._v(" keyboard_arrow_down ")
                      ]),
                      _vm._v(" Dados de exibição na mensagem")
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass: "ma-3 pa-3",
                      attrs: { color: "grey lighten-3" }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "d-flex justify-center" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "align-self-center",
                              attrs: { lg: "6" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-5",
                                attrs: {
                                  solo: "",
                                  rules: _vm.requiredRules,
                                  required: "",
                                  readonly: !_vm.newCampaign,
                                  "prepend-inner-icon": "mdi-domain",
                                  placeholder:
                                    "Nome de exibição do Apresentante",
                                  hint:
                                    "Esse nome irá ser exibido em todas as mensagens"
                                },
                                model: {
                                  value: _vm.campanha.company_name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.campanha, "company_name", $$v)
                                  },
                                  expression: "campanha.company_name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "align-self-center",
                              attrs: { lg: "5" }
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mt-5",
                                attrs: {
                                  solo: "",
                                  rules: _vm.requiredRules,
                                  required: "",
                                  readonly: !_vm.newCampaign,
                                  "prepend-inner-icon": "mdi-link",
                                  placeholder: "Link de contato",
                                  hint:
                                    "O link de contato será exibido em todas as mensagens"
                                },
                                model: {
                                  value: _vm.campanha.company_url,
                                  callback: function($$v) {
                                    _vm.$set(_vm.campanha, "company_url", $$v)
                                  },
                                  expression: "campanha.company_url"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.newCampaign
                    ? _c(
                        "v-card-title",
                        {
                          staticClass:
                            "grey--text darken-2 font-weight-black justify-center title-2"
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "primary--text ma-1 pa-1" },
                            [_vm._v(" keyboard_arrow_down ")]
                          ),
                          _vm._v(" Selecione um template para a campanha")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.templateLoading && _vm.newCampaign
                    ? _c(
                        "v-card",
                        {
                          staticClass: "ma-3 pa-3 d-flex justify-center",
                          staticStyle: { "border-radius": "20px" },
                          attrs: { color: "grey lighten-3" }
                        },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "ma-3 pa-3 secondary--text" },
                            [
                              _c("v-progress-circular", {
                                staticClass: "mx-3 px-3",
                                attrs: { indeterminate: "", color: "primary" }
                              }),
                              _vm._v("Buscando templates ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.templateLoading && !_vm.newCampaign
                    ? _c(
                        "v-card",
                        {
                          staticClass: "ma-3 pa-3",
                          staticStyle: { "border-radius": "20px" },
                          attrs: { color: "grey lighten-3" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "green ma-4 pa-4 white--text" },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mx-3 white--text" },
                                    [_vm._v("mdi-whatsapp")]
                                  ),
                                  _vm._v(" Whatsapp ")
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "white--text" },
                                [_vm._v(" Detalhes da mensagem registrada: ")]
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "white--text" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.campanha.message) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.templateLoading && _vm.newCampaign
                    ? _c(
                        "v-card",
                        {
                          staticClass: "ma-3 pa-3",
                          staticStyle: { "border-radius": "20px" },
                          attrs: { color: "grey lighten-3" }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-row",
                                { staticClass: "d-flex justify-center" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "ma-2 pa-3",
                                      attrs: { cols: "10", sm: "8", lg: "3" }
                                    },
                                    [
                                      _c("v-card-title", [
                                        _vm._v(" Selecione um template: ")
                                      ]),
                                      _c("v-select", {
                                        attrs: {
                                          "prepend-inner-icon":
                                            "mdi-message-processing",
                                          solo: "",
                                          items: _vm.status,
                                          label: "Selecione um Template",
                                          "item-value": "id",
                                          "item-text": "name",
                                          "return-object": true
                                        },
                                        on: { input: _vm.atualizaInput },
                                        model: {
                                          value: _vm.campanhaSelecionada,
                                          callback: function($$v) {
                                            _vm.campanhaSelecionada = $$v
                                          },
                                          expression: "campanhaSelecionada"
                                        }
                                      }),
                                      _c("v-card-text", [
                                        _vm._v(" Os "),
                                        _c("strong", [
                                          _vm._v("templates de mensagens")
                                        ]),
                                        _vm._v(
                                          " são préviamente aprovados através da ferramenta de envio. Por esse motivo todos os templates que são utilizados para mensagens transacionais na Recuperi precisam ser préviamente verificados e aprovados por nossa equipe. Caso esteja buscando um template que não consta nessa lista, por favor entre em contato. "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { lg: "8" } }, [
                                    !_vm.campanhaSelecionada
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "border-radius": "20px"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass:
                                                  "ma-3 pa-3 d-flex justify-center align-center",
                                                attrs: { height: "400px" }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "mx-3 px-3" },
                                                  [
                                                    _vm._v(
                                                      "mdi-information-outline"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(
                                                  "Nenhum template selecionado. "
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.campanhaSelecionada
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "border-radius": "20px"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "ma-5",
                                                staticStyle: {
                                                  "border-radius": "10px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "chat-header",
                                                    attrs: { dark: "" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-center"
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticStyle: {
                                                            "border-radius":
                                                              "50%",
                                                            margin: "10px"
                                                          },
                                                          attrs: {
                                                            src: _vm.logo,
                                                            height: "50px",
                                                            width: "50px"
                                                          }
                                                        }),
                                                        _c(
                                                          "v-card-text",
                                                          {
                                                            staticClass:
                                                              "white--text",
                                                            staticStyle: {
                                                              "font-size":
                                                                "20px",
                                                              "font-weight":
                                                                "700"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Comunicação - Recuperi "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                ),
                                                _c("v-divider"),
                                                _c(
                                                  "v-container",
                                                  {
                                                    staticClass:
                                                      "chat-background"
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { row: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "text-xs-right",
                                                            attrs: {
                                                              xs12: "",
                                                              "mt-10": "",
                                                              "mb-15": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "chip-chat"
                                                              },
                                                              [
                                                                _vm.newCampaign
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.mensagemExibicao
                                                                        )
                                                                      )
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      right: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "done_all"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs10: "" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "my-input",
                                                              attrs: {
                                                                "single-line":
                                                                  "",
                                                                color: "teal",
                                                                outline: ""
                                                              },
                                                              model: {
                                                                value: _vm.text,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.text = $$v
                                                                },
                                                                expression:
                                                                  "text"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs2: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "teal--text",
                                                                attrs: {
                                                                  large: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      large: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "send"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newCampaign
                    ? _c(
                        "v-card-title",
                        {
                          staticClass:
                            "grey--text darken-2 font-weight-black justify-center title-2"
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "primary--text ma-1 pa-1" },
                            [_vm._v(" keyboard_arrow_down ")]
                          ),
                          _vm._v(
                            " Seu template está pronto? Ative no botão abaixo"
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newCampaign
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "3" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black"
                                    },
                                    [_vm._v(" Ativo/Inativo ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "12", md: "1" } },
                                [
                                  _c("v-switch", {
                                    staticClass: "ml-5",
                                    attrs: {
                                      color: "success",
                                      inset: "",
                                      "not-required": ""
                                    },
                                    model: {
                                      value: _vm.campanha.is_active,
                                      callback: function($$v) {
                                        _vm.$set(_vm.campanha, "is_active", $$v)
                                      },
                                      expression: "campanha.is_active"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "3" } },
                                [
                                  _c("v-card-text", {}, [
                                    _vm._v(
                                      " Ao ativar, estará permitindo o envio da mensagem após salvar essa página. "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newCampaign && _vm.campanha.is_active
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "12", md: "9" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black d-flex justify-left"
                                    },
                                    [_vm._v(" Essa campanha está ativa: ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: { cols: "1", sm: "3", md: "3" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "error" },
                                      on: { click: _vm.desabilitarCampanha }
                                    },
                                    [_vm._v("desabilitar campanha")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "12", md: "12" } },
                                [
                                  _c("v-card-text", {}, [
                                    _vm._v(
                                      " Ao desabilitar, estará encerrando os envios recentes. Possívelmente, caso a campanha já estiver em processamento não será possível encerrar todos os envios. "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newCampaign && !_vm.campanha.is_active
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "12", md: "6" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black d-flex justify-center"
                                    },
                                    [_vm._v(" Essa campanha está inativa: ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex justify-center",
                                  attrs: { cols: "1", sm: "6", md: "6" }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { disabled: "", color: "success" }
                                    },
                                    [_vm._v("habilitar campanha")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newCampaign
                    ? _c(
                        "v-card-title",
                        {
                          staticClass:
                            "grey--text darken-2 font-weight-black justify-center title-2"
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "primary--text ma-1 pa-1" },
                            [_vm._v(" keyboard_arrow_down ")]
                          ),
                          _vm._v(
                            " Confira abaixo os Títulos Vinculados a Campanha "
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-3 pa-3",
                              attrs: { "x-small": "", fab: "" },
                              on: { click: _vm.atualiza }
                            },
                            [_c("v-icon", [_vm._v("sync")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.newCampaign
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _vm.loadingContatos
                            ? _c(
                                "v-card-title",
                                { staticClass: "ma-3 pa-3 secondary--text" },
                                [
                                  _c("v-progress-circular", {
                                    staticClass: "mx-3 px-3",
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary"
                                    }
                                  }),
                                  _vm._v("Buscando contatos ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headersResults,
                              items: _vm.titulosResult,
                              loading: _vm.loadingContatos,
                              "items-per-page": 5
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.created_at`,
                                  fn: function({ item }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(item.created_at)
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: `item.updated_at`,
                                  fn: function({ item }) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatDate(item.updated_at)
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: `item.sent`,
                                  fn: function({ item }) {
                                    return [
                                      _c("v-switch", {
                                        style: {
                                          color:
                                            item.sent == true ? "green" : "red"
                                        },
                                        attrs: {
                                          color:
                                            item.sent == true ? "green" : "red",
                                          readonly: "",
                                          inset: ""
                                        },
                                        model: {
                                          value: item.sent,
                                          callback: function($$v) {
                                            _vm.$set(item, "sent", $$v)
                                          },
                                          expression: "item.sent"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: `item.bounce`,
                                  fn: function({ item }) {
                                    return [
                                      _c("v-switch", {
                                        style: {
                                          color:
                                            item.bounce == true
                                              ? "green"
                                              : "red"
                                        },
                                        attrs: {
                                          color:
                                            item.bounce == true
                                              ? "green"
                                              : "red",
                                          readonly: "",
                                          inset: ""
                                        },
                                        model: {
                                          value: item.bounce,
                                          callback: function($$v) {
                                            _vm.$set(item, "bounce", $$v)
                                          },
                                          expression: "item.bounce"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: `item.processing_status`,
                                  fn: function({ item }) {
                                    return [
                                      item.processing_status === "F"
                                        ? _c("span", [_vm._v("Finalizado")])
                                        : _vm._e(),
                                      item.processing_status === "W"
                                        ? _c("span", [_vm._v("Pendente")])
                                        : _vm._e(),
                                      item.processing_status === "E"
                                        ? _c("span", [_vm._v("Erro")])
                                        : _vm._e(),
                                      item.processing_status === ""
                                        ? _c("span", [
                                            _vm._v(" Não encontrado")
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newCampaign
                    ? _c(
                        "v-card-title",
                        {
                          staticClass:
                            "grey--text darken-2 font-weight-black justify-center title-2"
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "primary--text ma-1 pa-1" },
                            [_vm._v(" keyboard_arrow_down ")]
                          ),
                          _vm._v(
                            " Vamos Selecionar os títulos para a campanha:"
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.newCampaign
                    ? _c(
                        "v-card",
                        { staticClass: "ma-3 pa-3" },
                        [
                          _c(
                            "v-row",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "my-2",
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.botaoFiltrar.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-3 mt-3 mb-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "5",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "mt-5",
                                                style: {
                                                  maxHeight: "15rem",
                                                  overflowY: "auto"
                                                },
                                                attrs: {
                                                  "auto-grow": "",
                                                  solo: "",
                                                  small: "",
                                                  rows: "1",
                                                  label: "Número do Título",
                                                  hint:
                                                    "Informe o Número do Título",
                                                  placeholder: "Ex.:477977"
                                                },
                                                model: {
                                                  value:
                                                    _vm.filtros.ds_nosso_numero,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "ds_nosso_numero",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                filtros.ds_nosso_numero\n                                            "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row align-center",
                                              attrs: {
                                                cols: "4",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: [
                                                      "###.###.###-##",
                                                      "XX.XXX.XXX/XXXX-##"
                                                    ],
                                                    expression:
                                                      "[\n                                                '###.###.###-##',\n                                                'XX.XXX.XXX/XXXX-##'\n                                            ]"
                                                  }
                                                ],
                                                staticClass: "mt-5",
                                                attrs: {
                                                  solo: "",
                                                  small: "",
                                                  label: "CPF/CNPJ",
                                                  hint:
                                                    "Informe o Número do CPF/CNPJ",
                                                  placeholder:
                                                    "Ex.:000.000.000-00",
                                                  clearable: "",
                                                  "data-cy": "filtroCpfCnpj"
                                                },
                                                model: {
                                                  value:
                                                    _vm.filtros
                                                      .nu_identificacao_devedor,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "nu_identificacao_devedor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "\n                                                filtros.nu_identificacao_devedor\n                                            "
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "5",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mt-5",
                                                attrs: {
                                                  solo: "",
                                                  small: "",
                                                  label: "Nome",
                                                  hint:
                                                    "Informe o nome para efetuar a busca",
                                                  placeholder: "Ex.: José",
                                                  clearable: "",
                                                  "data-cy": "nomeDevedor"
                                                },
                                                model: {
                                                  value: _vm.filtros.nm_devedor,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "nm_devedor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filtros.nm_devedor"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "4",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "mt-5",
                                                attrs: {
                                                  solo: "",
                                                  small: "",
                                                  items: _vm.listaSituacoes,
                                                  label: "Status",
                                                  "item-text": "nm_status",
                                                  "item-value": "cd_status",
                                                  clearable: "",
                                                  hint: "Selecione o Status",
                                                  "persistent-hint": "",
                                                  "data-cy": "statusTitulo"
                                                },
                                                model: {
                                                  value: _vm.filtros.cd_status,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "cd_status",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filtros.cd_status"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-3 mt-1 mb-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "5",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "max-width": "290px",
                                                    "min-width": "290px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                solo: "",
                                                                small: "",
                                                                label:
                                                                  "Data de cadastro",
                                                                hint:
                                                                  "Data de cadastro: inícial e final",
                                                                placeholder:
                                                                  "Selecione no botão ao lado",
                                                                clearable: ""
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.limparDataCadastro
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.computedDateCadastroFormattedRange,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.computedDateCadastroFormattedRange = $$v
                                                                },
                                                                expression:
                                                                  "\n                                                        computedDateCadastroFormattedRange\n                                                    "
                                                              }
                                                            }),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "grey--text mt-3 ml-2",
                                                                attrs: {
                                                                  fab: "",
                                                                  "x-small": "",
                                                                  elevation: "0"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-calendar "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    2464181611
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.menuDataCadastroRange,
                                                    callback: function($$v) {
                                                      _vm.menuDataCadastroRange = $$v
                                                    },
                                                    expression:
                                                      "menuDataCadastroRange"
                                                  }
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      range: "",
                                                      color: "primary",
                                                      locale: "pt-br"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filtros
                                                          .dt_cadastro_range,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.filtros,
                                                          "dt_cadastro_range",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                                                    filtros.dt_cadastro_range\n                                                "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "4",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "nudge-right": 40,
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "max-width": "290px",
                                                    "min-width": "290px"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function({ on }) {
                                                          return [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                solo: "",
                                                                label:
                                                                  "Data de movimentação",
                                                                placeholder:
                                                                  "Selecione no botão ao lado",
                                                                hint:
                                                                  "Data Movimento: Inicial e Final",
                                                                clearable: ""
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.limparDataMovimento
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.computedDateMovimentoFormattedRange,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.computedDateMovimentoFormattedRange = $$v
                                                                },
                                                                expression:
                                                                  "\n                                                        computedDateMovimentoFormattedRange\n                                                    "
                                                              }
                                                            }),
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "grey--text mt-3 ml-2",
                                                                attrs: {
                                                                  fab: "",
                                                                  "x-small": "",
                                                                  elevation:
                                                                    "0",
                                                                  "data-cy":
                                                                    "dataMovimentoFilter"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-calendar "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1242983555
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.menuDataMovimentoRange,
                                                    callback: function($$v) {
                                                      _vm.menuDataMovimentoRange = $$v
                                                    },
                                                    expression:
                                                      "menuDataMovimentoRange"
                                                  }
                                                },
                                                [
                                                  _c("v-date-picker", {
                                                    attrs: {
                                                      range: "",
                                                      color: "primary",
                                                      locale: "pt-br"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filtros
                                                          .dt_arquivo_movimento_range,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.filtros,
                                                          "dt_arquivo_movimento_range",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "\n                                                    filtros.dt_arquivo_movimento_range\n                                                "
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-3 d-flex justify-start align-center",
                                              attrs: {
                                                cols: "5",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  solo: "",
                                                  small: "",
                                                  items: _vm.listaUfs,
                                                  label: "UF",
                                                  clearable: "",
                                                  "return-object": true
                                                },
                                                model: {
                                                  value:
                                                    _vm.filtros.cd_uf_devedor,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "cd_uf_devedor",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filtros.cd_uf_devedor"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-3 d-flex justify-start align-center",
                                              attrs: {
                                                cols: "4",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  solo: "",
                                                  small: "",
                                                  items: _vm.listaCarteiras,
                                                  label: "Carteiras",
                                                  clearable: "",
                                                  "return-object": true
                                                },
                                                model: {
                                                  value:
                                                    _vm.filtros.cd_carteira,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "cd_carteira",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filtros.cd_carteira"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mx-3 mt-0 mb-0",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          !_vm.isUsuarioAgrupador
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-3 d-flex justify-start align-center",
                                                  attrs: {
                                                    cols: "5",
                                                    xs: "2",
                                                    sm: "2",
                                                    md: "3",
                                                    lg: "3"
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      solo: "",
                                                      dense: "",
                                                      disabled:
                                                        _vm.loadingAgrupador ||
                                                        _vm.usuarioAgrupador !=
                                                          null,
                                                      loading:
                                                        _vm.loadingAgrupador,
                                                      items: _vm.agrupadores,
                                                      label: "Agrupador",
                                                      "item-text":
                                                        "nm_agrupador",
                                                      "item-value":
                                                        "ds_agrupador",
                                                      "data-cy": "agrupador"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.filtros
                                                          .ds_agrupador,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.filtros,
                                                          "ds_agrupador",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "filtros.ds_agrupador"
                                                    }
                                                  }),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function({
                                                              on,
                                                              attrs
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mb-5 ml-2",
                                                                    attrs: {
                                                                      fab: "",
                                                                      "x-small":
                                                                        "",
                                                                      elevation:
                                                                        "0"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              color:
                                                                                "grey"
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-information-outline "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        1637394943
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Selecione o agrupador que deseja filtrar"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 mt-0 mb-0" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-3 pt-0 pb-3",
                                              attrs: {
                                                cols: "7",
                                                xs: "7",
                                                sm: "7",
                                                md: "7",
                                                lg: "7",
                                                align: "left"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-h6",
                                                  staticStyle: {
                                                    color: "#6c6c6c"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Data de atualização do Título "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-normal",
                                                  staticStyle: {
                                                    color: "#6c6c6c"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Pesquise por títulos atualizados dentro do prazo (em dias) selecionado. "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-end align-end px-3 pt-0 pb-3",
                                              attrs: {
                                                cols: "5",
                                                xs: "5",
                                                sm: "5",
                                                md: "5",
                                                lg: "5",
                                                align: "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setDataMovimentoFiltro(
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Até 1D ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setDataMovimentoFiltro(
                                                        7
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Até 7D ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "ma-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setDataMovimentoFiltro(
                                                        30
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Até 30D ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "my-1 mr-0 ml-1",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.setDataMovimentoFiltro(
                                                        90
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Até 90D ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 mt-2 mb-0 pb-1" },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "px-3 pt-0 pb-3",
                                              attrs: {
                                                cols: "7",
                                                xs: "4",
                                                sm: "4",
                                                md: "6",
                                                lg: "6",
                                                align: "left"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-bold text-h6",
                                                  staticStyle: {
                                                    color: "#6c6c6c"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Intervalo de datas com base na atualização do Título "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "font-weight-normal",
                                                  staticStyle: {
                                                    color: "#6c6c6c"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Pesquise por títulos atualizados dentro do período selecionado (em dias) através do range ao lado. "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "d-flex flex-row justify-end align-end px-3 pt-0 pb-3",
                                              attrs: {
                                                cols: "5",
                                                xs: "5",
                                                sm: "8",
                                                md: "6",
                                                lg: "6",
                                                align: "center"
                                              }
                                            },
                                            [
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      color: "transparent"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-text",
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "5",
                                                                  xs: "5",
                                                                  sm: "5",
                                                                  md: "5",
                                                                  lg: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: "",
                                                                      color:
                                                                        "transparent"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-subheader",
                                                                      [
                                                                        _vm._v(
                                                                          "Títulos atualizados entre " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .valueMulti[0]
                                                                            ) +
                                                                            " dias e " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .valueMulti[1]
                                                                            ) +
                                                                            " dias"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticStyle: {
                                                                          "min-width":
                                                                            "400px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-range-slider",
                                                                          {
                                                                            attrs: {
                                                                              ripple: false,
                                                                              min:
                                                                                "0",
                                                                              max:
                                                                                "730",
                                                                              "thumb-label":
                                                                                "always",
                                                                              "thumb-size": 35
                                                                            },
                                                                            on: {
                                                                              input: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.setDataMovimentoFiltroRange(
                                                                                  _vm.valueMulti
                                                                                )
                                                                              }
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.valueMulti,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.valueMulti = $$v
                                                                              },
                                                                              expression:
                                                                                "\n                                                                            valueMulti\n                                                                        "
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { staticClass: "mx-3 mt-2 mb-0 pb-1" },
                                        [
                                          _c("v-col", {
                                            staticClass:
                                              "pa-3 d-flex justify-start align-center",
                                            attrs: {
                                              cols: "2",
                                              xs: "2",
                                              sm: "2",
                                              md: "2",
                                              lg: "2"
                                            }
                                          }),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass:
                                                "pa-3 d-flex justify-start align-center",
                                              attrs: {
                                                cols: "3",
                                                xs: "3",
                                                sm: "3",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                staticClass:
                                                  "mt-n1 d-flex justify-center",
                                                attrs: {
                                                  "hide-details": "",
                                                  small: "",
                                                  label:
                                                    "Não mostrar Irregulares"
                                                },
                                                model: {
                                                  value: _vm.irregulares,
                                                  callback: function($$v) {
                                                    _vm.irregulares = $$v
                                                  },
                                                  expression: "irregulares"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("v-col", {
                                            staticClass:
                                              "d-flex flex-row justify-space-around",
                                            attrs: {
                                              cols: "4",
                                              xs: "4",
                                              sm: "4",
                                              md: "4",
                                              lg: "4",
                                              align: "center"
                                            }
                                          }),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "d-flex flex-row",
                                              attrs: {
                                                cols: "6",
                                                xs: "6",
                                                sm: "6",
                                                md: "3",
                                                lg: "3",
                                                align: "center"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "d-flex justify-center",
                                                  attrs: {
                                                    block: "",
                                                    color: "primary",
                                                    loading: _vm.loadingFiltros,
                                                    "data-cy": "filtrarTitulos"
                                                  },
                                                  on: {
                                                    click: _vm.botaoFiltrar
                                                  }
                                                },
                                                [
                                                  _vm._v(" Filtrar "),
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "ml-2" },
                                                    [_vm._v("search")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "12", md: "12" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "elevation-1 pa-3",
                                    attrs: {
                                      headers: _vm.headers,
                                      items: _vm.titulos,
                                      "item-text": "titulo",
                                      options: _vm.options,
                                      loading: _vm.loading,
                                      "single-select": _vm.singleSelect,
                                      "item-key": "id_titulo",
                                      "show-select": "",
                                      "footer-props": {
                                        itemsPerPageOptions: [
                                          50,
                                          1000,
                                          5000,
                                          -1
                                        ],
                                        itemsPerPageText: "Itens por página:"
                                      },
                                      "server-items-length": _vm.totalTitulos,
                                      "loading-text": "Pesquisando títulos...",
                                      "no-data-text":
                                        "Preencha os filtros e clique em filtrar...",
                                      "no-results-text":
                                        "Nenhum título encontrado..."
                                    },
                                    on: {
                                      "update:options": function($event) {
                                        _vm.options = $event
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: `item.id_titulo`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.id_titulo)
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.id_titulo)
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.ds_nosso_numero`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.ds_nosso_numero
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.ds_nosso_numero
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.dt_cadastro`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.dt_cadastro)
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.dt_cadastro)
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.dt_vencimento`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatDataVencimento(
                                                            item
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatDataVencimento(
                                                            item
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.nm_devedor`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.nm_devedor)
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.nm_devedor)
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.vl_saldo_protesto`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.vl_saldo_protesto
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.vl_saldo_protesto
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.cd_status`,
                                          fn: function({ item }) {
                                            return [
                                              _c(
                                                "span",
                                                { style: { color: "black" } },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getDescSituacao(item)
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.dt_atualizacao`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.dt_atualizacao
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.dt_atualizacao
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.cd_uf_devedor`,
                                          fn: function({ item }) {
                                            return [
                                              item.ds_revisao != ""
                                                ? _c(
                                                    "span",
                                                    {
                                                      style: { color: "red" },
                                                      attrs: {
                                                        title:
                                                          "Este título precisa ser revisado. "
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.cd_uf_devedor
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      style: { color: "black" }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.cd_uf_devedor
                                                        )
                                                      )
                                                    ]
                                                  )
                                            ]
                                          }
                                        },
                                        {
                                          key: `item.oferta`,
                                          fn: function({ item }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                {
                                                  style: {
                                                    color:
                                                      item.oferta > 0
                                                        ? "green"
                                                        : "red"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getBolean(
                                                          item.oferta
                                                        )
                                                      )
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selecionados,
                                      callback: function($$v) {
                                        _vm.selecionados = $$v
                                      },
                                      expression: "selecionados"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "end",
                padding: "8px 48px 8px 16px"
              }
            },
            [
              _vm.newCampaign
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-5",
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.verificaRules,
                        color: "green white--text"
                      },
                      on: { click: _vm.ativaModal }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-icon",
                            { attrs: { small: "", cass: "white--text" } },
                            [_vm._v("mdi-check")]
                          ),
                          _vm._v("Ativar Campanha")
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: { color: "gray", width: "120", loading: _vm.loading },
                  on: { click: _vm.fecharModal }
                },
                [_vm._v(" Fechar ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.modalAvisoConfirmacao
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800px",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.modalAvisoConfirmacao,
                callback: function($$v) {
                  _vm.modalAvisoConfirmacao = $$v
                },
                expression: "modalAvisoConfirmacao"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  _vm.modalAvisoConfirmacao = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _vm._v(" Condições de Criação da Campanha ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-8 py-4" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "d-flex text-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "error",
                                            size: "200%"
                                          }
                                        },
                                        [_vm._v(" mdi-information ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "title error--text" },
                                        [
                                          _vm._v(
                                            " Essa campanha possui " +
                                              _vm._s(_vm.titulosFailQtd()) +
                                              " título(s) com dados incompletos "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "error--text" },
                                        [
                                          _vm._v(
                                            " Os títulos que se encontram nessa lista serão removidos da campanha. Para que possa utilizar um título para envio de mensagens ele precisa ter informações de contato cadastradas "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-8 py-4" },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.titulosFailHeader,
                                          items: _vm.titulosFail
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: `item.value`,
                                              fn: function({ item }) {
                                                return [
                                                  item.value === ""
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "error--text"
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-2",
                                                              attrs: {
                                                                size: "small"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-close"
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v("Não existe")
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          style: {
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.value)
                                                          )
                                                        ]
                                                      )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "d-flex text-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mt-5",
                                          attrs: {
                                            color: "success",
                                            size: "200%"
                                          }
                                        },
                                        [_vm._v(" mdi-information ")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "title success--text" },
                                        [
                                          _vm._v(
                                            " Essa campanha possui " +
                                              _vm._s(
                                                _vm.titulosSelecionadosQtd()
                                              ) +
                                              " título(s) prontos para envio. "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "success--text" },
                                        [
                                          _vm._v(
                                            " Os títulos que se encontram nessa lista foram verificados e aprovados para envio da campanha de whatsapp. "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "px-8 py-4" },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          headers: _vm.titulosFailHeader,
                                          items: _vm.selecionadosFinal
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "d-flex text-center" },
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "12" } },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mt-5",
                                      attrs: { color: "primary", size: "200%" }
                                    },
                                    [_vm._v(" mdi-information ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "title primary--text" },
                                    [
                                      _vm._v(
                                        " Tem certeza dos dados informados? "
                                      )
                                    ]
                                  ),
                                  _c("v-card-text", { staticClass: "title" }, [
                                    _vm._v(
                                      " Ao ativar a campanha, ela estará ativa e disponível para envio a qualquer momento. "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      " Importante: O envio de mensagens de whatsapp pode gerar custos adicionais. Confirme a criação dessa campanha caso tenha total ciência de que a Recuperi poderá entrar em contato com todos os devedores selecionados, através do contato informado. "
                                    )
                                  ]),
                                  _c("v-card-text", [
                                    _vm._v(
                                      " Em caso de dúvidas, entre em contato com nosso suporte através do e-mail: suporte@recuperi.com.br "
                                    )
                                  ]),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "d-flex justify-center",
                                      staticStyle: {
                                        display: "flex",
                                        "align-content": "center",
                                        "justify-content": "center",
                                        "text-align": "center"
                                      }
                                    },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: `Li e concordo com as condições acima.`
                                        },
                                        model: {
                                          value: _vm.liConcordo,
                                          callback: function($$v) {
                                            _vm.liConcordo = $$v
                                          },
                                          expression: "liConcordo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("v-col", { attrs: { lg: "2" } })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "end",
                        padding: "8px 48px 8px 16px"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "success white--text",
                            width: "120",
                            loading: _vm.loading,
                            disabled: !_vm.liConcordo
                          },
                          on: { click: _vm.validarSalvarCampanha }
                        },
                        [_vm._v(" Confirmar ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            color: "grey white--text",
                            width: "120",
                            loading: _vm.loading
                          },
                          on: {
                            click: function($event) {
                              _vm.modalAvisoConfirmacao = false
                            }
                          }
                        },
                        [_vm._v(" Cancelar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVisible
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogVisible,
                callback: function($$v) {
                  _vm.dialogVisible = $$v
                },
                expression: "dialogVisible"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v("Resultado da criação de Campanhas")
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _vm._l(_vm.responseData.Msg, function(
                        messageItem,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.responseData.Msg,
                                expression: "responseData.Msg"
                              }
                            ],
                            key: index
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ma-3 pa-3",
                                attrs: { color: "grey lighten-3" }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "grey white--text" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "mx-3 white--text" },
                                      [_vm._v("mdi-information-outline")]
                                    ),
                                    _vm._v("Mensagem")
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card-text",
                                  { staticClass: "ma-3 pa-3" },
                                  [_c("b", [_vm._v(_vm._s(messageItem))])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      _vm._l(_vm.responseData.Success, function(
                        successItem,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.responseData.Success,
                                expression: "responseData.Success"
                              }
                            ],
                            key: index
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ma-3 pa-3",
                                attrs: { color: "grey lighten-3" }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "success white--text" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "mx-3 white--text" },
                                      [_vm._v("mdi-check-decagram")]
                                    ),
                                    _vm._v(
                                      "Resultado de campanhas com sucesso:"
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(successItem.whatsapp_campaign, function(
                                  campaignItem,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Detalhes da Campanha:")
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v(
                                                "ID da campanha de whatsapp:"
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(campaignItem.id) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [_vm._v("Environment:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.environment
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("Carimbo de Tempo:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.time_stamp
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("Nome da Campanha:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.campaign_name
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [_vm._v("Status:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(campaignItem.is_active) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("URL de destino:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.company_url
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v(
                                                "Referência de contato nas mensagens:"
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.company_name
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                _vm._l(successItem.contacts, function(
                                  contactItem,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" Contatos: ")
                                          ]),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Nome:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.name) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Contato:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.value) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Tipo:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.type) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      }),
                      _vm._l(_vm.responseData.Error, function(
                        errorItem,
                        index
                      ) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.responseData.Error,
                                expression: "responseData.Error"
                              }
                            ],
                            key: index
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "ma-3 pa-3",
                                attrs: { color: "grey lighten-3" }
                              },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "error white--text" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "mx-3 white--text" },
                                      [_vm._v("mdi-close")]
                                    ),
                                    _vm._v("Resultado de campanhas com erro:")
                                  ],
                                  1
                                ),
                                _vm._l(errorItem.whatsapp_campaign, function(
                                  campaignItem,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v("Detalhes da Campanha:")
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v(
                                                "ID da campanha de whatsapp:"
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(campaignItem.id) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [_vm._v("Environment:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.environment
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("Carimbo de Tempo:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.time_stamp
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("Nome da Campanha:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.campaign_name
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [_vm._v("Status:")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(campaignItem.is_active) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v("URL de destino:")
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.company_url
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("v-card-text", [
                                            _c("b", [
                                              _vm._v(
                                                "Referência de contato nas mensagens:"
                                              )
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  campaignItem.company_name
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                _vm._l(errorItem.contacts, function(
                                  contactItem,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c("v-card-title", [
                                            _vm._v(" Contatos: ")
                                          ]),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Nome:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.name) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Contato:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.value) +
                                                    " "
                                                )
                                              ]),
                                              _c("v-card-text", [
                                                _c("b", [_vm._v("Tipo:")]),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(contactItem.type) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-btn", { on: { click: _vm.closeDialog } }, [
                        _vm._v("Fechar Resultados")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }