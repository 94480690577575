var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.templateTitulos && !_vm.templateContatos
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex text-center" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "6",
                        sm: "12",
                        md: "12",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "primary--text font-weight-black justify-center title-2"
                        },
                        [
                          _vm._v(
                            " Para definir quais contatos irão receber as mensagens, selecione uma opção abaixo:"
                          )
                        ]
                      ),
                      _c("v-card-text", [
                        _vm._v(
                          "Você poderá optar por selecionar os contatos com base em títulos da base Recuperi (selecionando por exemplo: Protestados, Cancelados, Enviados) ou também poderá fazer o upload de um arquivo XLS com os contatos que deseja acionar"
                        )
                      ])
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { lg: "3" } }),
                  _c(
                    "v-col",
                    { attrs: { lg: "3" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-5",
                              attrs: { color: "primary", size: "300%" }
                            },
                            [_vm._v(" mdi-message-arrow-right ")]
                          ),
                          _c("v-card-text", { staticClass: "title" }, [
                            _vm._v(
                              " Enviar para todos os Títulos da Campanha com base no Status Selecionado "
                            )
                          ]),
                          _c(
                            "v-card-text",
                            { staticStyle: { "min-height": "130px" } },
                            [
                              _vm._v(
                                " Essa campanha já possui um template definido. Para ver os detalhes acesse a campanha na tela anterior. "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary white--text mb-10",
                              attrs: { loading: _vm.loading },
                              on: { click: _vm.ativaTitulos }
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ]),
                              _vm._v(" Títulos da Campanha ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { lg: "3" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mt-5",
                              attrs: { color: "primary", size: "300%" }
                            },
                            [_vm._v(" mdi-file-upload-outline ")]
                          ),
                          _c("v-card-text", { staticClass: "title" }, [
                            _vm._v(
                              " Fazer Upload de um arquivo XLS com contatos para a Campanha Selecionada "
                            )
                          ]),
                          _c(
                            "v-card-text",
                            { staticStyle: { "min-height": "130px" } },
                            [
                              _vm._v(
                                " Essa campanha já possui um template definido. Você irá definir aqui quais os contatos que irão receber a mensagem. "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary white--text mb-10",
                              attrs: { loading: _vm.loading },
                              on: {
                                click: function($event) {
                                  _vm.templateContatos = true
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ]),
                              _vm._v(" Fazer Upload ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { lg: "3" } })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: "success white--text",
              mensagemSnackbar: _vm.snackbarDesc
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e(),
      _vm.templateTitulos
        ? _c(
            "div",
            [
              _c(
                "v-container",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "d-flex text-center" },
                    [
                      _c("v-col", { attrs: { lg: "3" } }),
                      _c(
                        "v-col",
                        { attrs: { lg: "6" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mt-5",
                                  attrs: { color: "primary", size: "500%" }
                                },
                                [_vm._v(" mdi-bookmark-check ")]
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "title primary--text" },
                                [_vm._v(" Campanha configurada com sucesso! ")]
                              ),
                              _c("v-card-text", { staticClass: "title" }, [
                                _c("strong", [_vm._v("Tipo de envio: ")])
                              ]),
                              _c("v-card-text", { staticClass: "title" }, [
                                _vm._v(
                                  " contatos vinculados aos títulos da base Recuperi, Template selecionado na Campanha. "
                                )
                              ]),
                              _c(
                                "v-card-text",
                                { staticStyle: { "min-height": "130px" } },
                                [
                                  _vm._v(
                                    " O envio de mensagens para essa campanha está configurado! Poderá acompanhar o andamento das mensagens através dos nossos relatórios. "
                                  )
                                ]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary white--text mb-10",
                                  attrs: { disabled: "", loading: _vm.loading }
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v(" mdi-chevron-right ")
                                  ]),
                                  _vm._v(" Acompanhar Envios ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-col", { attrs: { lg: "3" } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.templateContatos
        ? _c(
            "div",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-container",
                    { attrs: { justify: "center" } },
                    [
                      _vm.contatosAtivos
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                dense: "",
                                type: "info",
                                color: "primary"
                              }
                            },
                            [
                              _vm._v(
                                " Importante: Essa campanha já possui contatos vinculados via Upload de arquivo XLS, por isso, agora você poderá incluir contatos apenas via Upload de contatos com arquivo XLS. Caso queira criar uma campanha com base em títulos/status, recomendamos criar uma nova campanha :) "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "primary--text font-weight-black justify-center title-2"
                        },
                        [
                          _vm._v(
                            " Faça Upload dos contatos para envio das mensagems Whatsapp:"
                          )
                        ]
                      ),
                      _c(
                        "v-card",
                        {
                          staticClass: "ma-3 pa-3",
                          staticStyle: { "border-radius": "20px" },
                          attrs: { color: "primary", elevation: "3" }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-3 pa-3" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "3", sm: "2" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "white", size: "1000%" }
                                    },
                                    [_vm._v(" mdi-whatsapp ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "4", sm: "10" } },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "display-1 mx-auto white--text justify-center font-weight-black"
                                    },
                                    [
                                      _vm._v(
                                        "Faça Upload do Arquivo XLS com os contatos"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "subtitle-1 mx-auto white--text justify-center font-weight-normal"
                                    },
                                    [
                                      _vm._v(
                                        "Utilize o template disponível no botão abaixo para fazer upload dos contatos."
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { cols: "3", sm: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { staticClass: "grey lighten-4 pa-3" },
                                        [
                                          _c("v-file-input", {
                                            ref: "myFileInput",
                                            staticClass: "mb-n7 white--text",
                                            attrs: {
                                              accept:
                                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                                              label:
                                                "Selecione o arquivo (.xls/.xlsx/ .xlsm)",
                                              "show-size": "",
                                              loading: _vm.loadingUpload,
                                              disabled: _vm.desabilitaBoxEnvio,
                                              chips: "",
                                              outlined: "",
                                              id: "fileInput",
                                              name: "file"
                                            },
                                            on: {
                                              change: _vm.uploadFileReference
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-3",
                                      attrs: {
                                        cols: "3",
                                        sm: "3",
                                        md: "12",
                                        align: "right"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "green white--text",
                                          attrs: {
                                            large: "",
                                            loading: _vm.loadingUpload,
                                            disabled: _vm.uploadOk
                                          },
                                          on: { click: _vm.upload }
                                        },
                                        [
                                          _vm._v(" enviar "),
                                          _c(
                                            "v-icon",
                                            { staticClass: "ml-2" },
                                            [_vm._v("cloud_upload")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mx-3",
                                          attrs: {
                                            color: "green white--text",
                                            large: "",
                                            href: _vm.linkModelo
                                          }
                                        },
                                        [
                                          _vm._v(" baixar template "),
                                          _c(
                                            "v-icon",
                                            { staticClass: "ml-2" },
                                            [_vm._v("cloud_download")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "6",
                                sm: "4",
                                md: "12",
                                "justify-center": ""
                              }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "primary--text font-weight-black justify-center title-2"
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v(" expand_more ")]
                                  ),
                                  _vm._v(" Status do Upload:")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mx-6 my-0 px-6 pb-6" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "ma-3 pa-3 grey lighten-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" Situação atual do envio: ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" A Enviar ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text display-2 font-weight-black text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.customerCountRead) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "ma-3 pa-3 grey lighten-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" Situação atual do envio: ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" Enviados ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text display-2 font-weight-black text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.customerCount) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6", sm: "4", md: "4" } },
                            [
                              _c(
                                "v-card",
                                { staticClass: "ma-3 pa-3 grey lighten-4" },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text subtitle-1 font-weight-light text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" Situação atual do envio: ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "primary--text display-1 font-weight-black text-center ma-0 pa-0"
                                    },
                                    [_vm._v(" Erro ")]
                                  ),
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "grey--text display-2 font-weight-black text-center"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.customerCountError) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "6",
                                    sm: "4",
                                    md: "12",
                                    "justify-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "primary--text font-weight-black justify-center title-2"
                                    },
                                    [
                                      _vm._v(
                                        " Contatos ativos para essa campanha:"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-data-table", {
                                staticClass: "elevation-1 pa-3",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.contacts,
                                  options: _vm.options,
                                  loading: _vm.loading,
                                  "footer-props": {
                                    itemsPerPageOptions: [50, 100, 500]
                                  },
                                  "loading-text": "Atualizando...",
                                  "no-data-text": "Não há resultados...",
                                  "no-results-text":
                                    "Nenhum título encontrado..."
                                },
                                on: {
                                  "update:options": function($event) {
                                    _vm.options = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: `item.enviado`,
                                      fn: function({ item }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              style: {
                                                color:
                                                  item.enviado != ""
                                                    ? "green"
                                                    : "red"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getBolean(item.enviado)
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: `item.status_envio`,
                                      fn: function({ item }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            {
                                              style: {
                                                color:
                                                  item.status_envio != ""
                                                    ? "green"
                                                    : "red"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getBolean(
                                                    item.status_envio
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              }),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "6",
                                    sm: "4",
                                    md: "12",
                                    "justify-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "primary--text font-weight-black justify-center title-2"
                                    },
                                    [_vm._v(" Importante:")]
                                  ),
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "grey--text justify-center subtitle-2"
                                    },
                                    [
                                      _vm._v(
                                        " Após concluir o upload, verifique se o status de cada contato está ativo, se estiver ativo na listagem ele está apto a receber uma mensagem Whatsapp da Recuperi."
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }